* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  max-width: 100vw;
}

html {
  height: 100vh;
}

body {
  width: 100vw;
  overflow-x: hidden;
  height: 100%;
  background-color: rgba(226, 191, 175);
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
