:root {
  --mainColor: rgba(0, 0, 0, 0.5);
  --responsive-mainColor: rgba(0, 0, 0, 0.8);
  --menubar-color: #fa7f4e;
  --text-color: white;
  --ancer-hover-color: #f58254;
  --responsive-header-background-color: black;
  --is-active-color: #c1613a;
  --header-font-family: HelveticaNeue, Helvetica, Sans-ns-Serif;
}

.logoContainer {
  margin: 0.4rem 0 0 4rem;
  width: 6rem;
  height: 60%;
  background-color: rgba(250, 174, 142, 0.5);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  border-radius: 10px;
}

.logo {
  width: 100%;
  height: 100%;
}

header {
  position: fixed;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 0 2rem;
  background-color: var(--mainColor);
  color: var(--text-color);
  z-index: 1;
  font-family: header-font-family;
}

nav a {
  margin: 0 1rem;
  color: var(--textColor);
  text-decoration: none;
  font-size: 1.3rem;
  font-weight: bolder;
  border: 2px solid white;
  border-radius: 100px;
  padding: 0.3rem 1.5rem 0.3rem 1.5rem;
}

nav a:hover {
  background-color: var(--ancer-hover-color);
  border: 2px solid white;

  color: #000;
}

.active {
  background-color: var(--is-active-color);
  pointer-events: inherit;
}

header .nav_btn {
  padding: 5px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--text-color);
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

.hideHamburger {
  display: none;
}

.showHamburger {
  display: inline;
}

.faTimes,
.faBars {
  color: var(--menubar-color);
  height: 1.8rem;
}

/*Media queries*/
@media only screen and (max-width: 1280px) {
  header {
    background-color: var(--mainColor);
  }
}

@media only screen and (width: 912px) {
  .logoContainer {
    margin: 0.4rem 0 0 1rem;
  }
}

@media only screen and (max-width: 900px) {
  header {
    height: 80px;
  }

  header .nav_btn {
    visibility: visible;
    opacity: 1;
    position: absolute;
    top: 2rem;
    right: 2.5rem;
  }

  header nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: var(--mainColor);
    transition: 1s;
    transform: translateY(-110vh);
    z-index: 2;
    flex-direction: column;
  }

  header .responsive_nav {
    transform: none;
    background-color: var(--responsive-header-background-color);
    background-color: var(--mainColor);
  }

  header .nav_close_btn {
    position: absolute;
    top: 2rem;
    right: 2.5rem;
  }

  nav a {
    font-size: 1.5rem;
  }

  .logoContainer {
    margin-left: 0;
    width: 4rem;
  }

  .logoContainer {
    margin: 1rem 0 0 0rem;
    height: 50%;
  }
}

@media only screen and (width: 820px) {
  .logoContainer {
    margin: 0.4rem 3rem 0 1rem;
  }
}
