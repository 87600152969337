.container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100vh;
    width: 100vw;
}

.miniContainer {
    box-shadow: 10px 10px 20px 10px;
    width: 45%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 12rem;
}

.secondContainer {
    margin: 3% 0 0 1%;
    align-self: self-start;
}

.imageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
}

.imageAndDesContentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}

.image {
    width: 100%;
    height: 20rem;
    border-radius: 10px;
}

.title {
    text-align: center;
    margin: 1rem 0 1.5rem 0;
    color: white;
    font-size: 2rem;
}

.descriptionContainer {
    color: white;
    padding: 0.5rem;
}

.description{
    font-size: 1.8rem;
}

.aAsBtn {
    display: flex;
    width: 100%;
    padding-left: 0;
    align-items: center;
    font-weight: bolder;
    cursor: pointer;
}

.aAsBtn div {
    font-weight: bold;
    font-size: 1.5rem;
}


.pointingFinger {
    width: 5rem;
    margin-right: 95%;
}

.pointingFinger:hover {
    filter: invert(60%) sepia(30%) saturate(648%) hue-rotate(319deg) brightness(100%) contrast(100%);
}


/*Media queries*/
@media only screen and (min-width: 769px) and (max-width: 1350px) {
    .miniContainer {
        width: 60%;
        top: 20rem;
    }
}

@media only screen and (width: 1280px) {
    .miniContainer {
        top: 7rem;
    }
}


@media only screen and (width: 1024px) {
    .miniContainer {
        width: 45%;
        top: 6rem;
    }

    .image{
        height: 12rem;
    }

    .title{
        font-size: 1.5rem;
    }

    .description{
        font-size: 1.2rem;
    }
}


@media only screen and (min-width: 820px)and (max-width: 912px) {
    .miniContainer {
        width: 75%;
    }
}


@media only screen and (max-width: 768px) {
    .miniContainer {
        width: 80%;
        top: 8rem
    }
    .title{
        font-size: 1.8rem;
    }
    .description{
        font-size: 1.2rem;
    }

    .image{
        height: 13rem;
    }
}


@media only screen and (width: 768px) {
    .image{
        height: 20rem;
    }
}


@media only screen and (width: 540px) {
    .miniContainer {
   top: 7rem;
    }

    .image{
        height: 13rem;
    }
}


@media only screen and (min-width: 280px) and (max-width: 375px) {
    .miniContainer {
        top: 5rem;
        width: 80%;
    }

    .image {
        /* height: 10rem; */
        height: 10rem;
    }

    .title{
        font-size: 1.5rem;
    }

    .description{
        font-size: 1rem;
    }

}

@media only screen and (width: 280px) {
    .miniContainer {
        top: 8rem;
        width: 90%;
        font-size: 1rem;
    }
    .image {
        height: 8rem;
    }

    .title{
        font-size: 1.5rem;
    }
    .description{
        font-size: 1rem;
    }
}
