.container {
  font-family: "Dancing Script", cursive;
}

.miniSContainer {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  margin: 5rem 0 5rem 0;
}

.mainFixedImage {
  position: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  margin: 0;
  padding: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  overflow: hidden;
}

.backgroundImageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0 2rem 0;
  width: 800px;
  margin: 5rem 0 0 0;
  border-top-right-radius: 500px;
  border-top-left-radius: 500px;
}

.sharingContainer, .appetizerContainer, .deserContainer,  .mainDishContainer{
  background-color: rgba(255, 255, 255, 0.4);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.monthlyContainer {
  padding-bottom: 4rem;
}

.contentContainer {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  z-index: 1;
  padding-bottom: 3rem;
}

.miniContainer {
  width: 100%;
  display: grid;
  grid-template-columns: 300px 300px;
  row-gap: 17px;
  column-gap: 40px;
  justify-content: center;
  column-count: 2;
  align-items: flex-start;
  width: auto;
}

.headTitle {
  padding: 0;
  font-size: 2.7rem;
  font-weight: bolder;
  text-align: center;
  margin: 2.5rem 0 2rem 0;
  border-bottom: 1px solid black;
  word-wrap: break-word;
  border-radius: 10px;
}

.allMenus {
  width: 100%;
  min-height: 2rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menuNavLink {
  width: 100%;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dishTitle {
  font-weight: bolder;
  font-size: 2.2rem;
  font-weight: 600;
  padding: 0.2rem;
  word-wrap: break-word;
  white-space: pre-line;
  width: auto;
  text-align: center;
}

.dishDescription {
  font-size: 1.9rem;
  font-weight: lighter;
}

.monthlyTitle {
  padding-top: 2.2rem;
  margin-bottom: 4.2rem;
}

.price {
  margin-top: 4%;
}

.kisdMenuTitle {
  margin: 1rem 0 2rem 0;
  font-size: 2.5rem;
  text-align: center;
  align-self: center;
  border-bottom: 1px solid black;
  word-wrap: break-word;
}

.supplementContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.supplementHeadTitle {
  font-size: 2.5rem;
  margin: 0 0 0.5rem 0;
}

.supplementTitle {
  font-size: 1.8rem;
}

.extraInfo {
  margin: 2.5rem 0 0 0;
  text-align: center;
  overflow-wrap: break-word;
  font-size: 1.7rem;
}

/*Medi queries*/
@media only screen and (max-width: 900px) {
  .backgroundImageContainer {
    border-top-right-radius: 400px;
    border-top-left-radius: 400px;
  }

  .mainFixedImage {
    background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    -o-background-size: 100% 100%;
  }
}


@media only screen and (width > 755px) and (width < 880px) {
  .headTitle,
  .kisdMenuTitle {
    font-size: 2.5rem;
  }
}


@media only screen and (width <= 750px) {
  .miniContainer {
    display: flex;
    flex-direction: column;
  }
}


@media only screen and (min-width: 700px) and (max-width: 900px) {
  .backgroundImageContainer {
    width: 700px;
    border-top-right-radius: 350px;
    border-top-left-radius: 350px;
  }
}


@media only screen and (max-width: 800px) {
  .backgroundImageContainer {
    width: 650px;
    border-top-right-radius: 370px;
    border-top-left-radius: 370px;
  }
}


@media only screen and (min-width: 600px) and (max-width: 700px) {
  .backgroundImageContainer {
    width: 560px;
    border-top-right-radius: 300px;
    border-top-left-radius: 300px;
  }
}


@media only screen and (min-width: 500px) and (max-width: 600px) {
  .backgroundImageContainer {
    width: 500px;
    border-top-right-radius: 300px;
    border-top-left-radius: 300px;
  }
}


@media only screen and (min-width: 300px) and (max-width: 550px) {
  .backgroundImageContainer {
    width: 450px;
    border-top-right-radius: 260px;
    border-top-left-radius: 260px;
  }
}


@media only screen and (max-width: 490px) {
  .backgroundImageContainer {
    width: 380px;
    border-top-right-radius: 260px;
    border-top-left-radius: 260px;
  }

  .supplementContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}


@media only screen and (min-width: 330px) and (max-width: 399px) {
  .backgroundImageContainer {
    width: 300px;
    border-top-right-radius: 260px;
    border-top-left-radius: 260px;
  }
}


@media only screen and (min-width: 260px) and (max-width: 330px) {
  .backgroundImageContainer {
    width: 260px;
    border-top-right-radius: 260px;
    border-top-left-radius: 260px;
  }
}


@media only screen and (min-width: 250px) and (max-width: 290px) {
  .backgroundImageContainer {
    width: 250px;
    border-top-right-radius: 260px;
    border-top-left-radius: 260px;
  }

  .headTitle,
  .kisdMenuTitle {
    font-size: 1.8rem;
  }

  .dishTitle,
  p {
    font-size: 1.5rem;
  }
}


@media only screen and (min-width: 100px) and (max-width: 260px) {
  .backgroundImageContainer {
    width: 200px;
    border-top-right-radius: 260px;
    border-top-left-radius: 260px;
  }

  .headTitle,
  .kisdMenuTitle {
    font-size: 1.8rem;
  }

  .dishTitle,
  p {
    font-size: 1.5rem;
  }
}
