footer {
  background-color: rgba(0, 0, 0, 0.5);
  margin-top: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 6rem;
  font-family: HelveticaNeue, Helvetica, Sans-ns-Serif;
  font-size: 1rem;
  z-index: 1;
  text-transform: uppercase;
}

.footerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.footerNavLinks {
  display: flex;
  justify-content: center;
  text-align: center;
  list-style: none;
}

.footerNavLinkItem a {
  font-weight: bold;
  color: white;
}

.footerUnderline {
  align-self: center;
  border-top: 3px solid #727070;
  border-top: 3px solid #cacaca;
  width: 50%;
  margin-top: 0.4rem;
}

.ulContainer{
  display: flex;
  justify-content: center;
  align-items: center;
}

ul{
  display: flex;
  width: auto;
}

ul li{
  margin: 0.5rem;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

img{
  width: 30px;
}

.copyright, .copyright a {
  text-align: center;
  list-style: none;
  color: white;
  margin-top: 0.5rem;
}

.copyright a {
cursor: pointer;
}

.copyright a:hover {
 color: #dcdcdc;
  }

.FooterLinkText {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flowUs {
  text-align: center;
  color: white;
}

.flowUs:hover {
  color: #fa7f4e; ;
}

.icons {
  background-color: white;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
  margin: 0;
  padding: 0;
}

.icons:hover {
  background-color: #FFA07A;
}

/*Medie queries*/
@media screen and (max-width: 768px) {
  footer {
    z-index: 0;
  }
}


@media screen and (max-width: 540px) {
img{
  width: 30px;
}
}


@media screen and (max-width: 400px) {
  .flowUs{
    font-size: 1rem;
  }

  img{
    width: 25px;
  }
  }