* {
    box-sizing: border-box;
    margin: 0;
}

.container {
    background-color: rgba(0, 0, 0, 0.7);
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    font-family: HelveticaNeue, Helvetica, Sans-ns-Serif;
    font-size: 1.2rem;
}

.miniContainer {
    background-color: rgb(242, 243, 245);
    width: 40%;
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-top: 7%;
    padding-top: 1rem;
    background-color: rgba(255, 255, 255, 0.6);
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
}

.modalBtn {
    color: white;
    width: 50%;
    height: 7%;
    margin-top: 1rem;
    background-image: linear-gradient(to right, #804832, #ee6731);
    border-radius: 30px;
    border: 5px;
    font-weight: 900;
    cursor: pointer;
    font-size: 1.2em;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalBtn:hover {
    background-color: #f5642c;
    background-image: linear-gradient(to right, #e9622c, #844e39);
    color: black;
}


/*Media queris*/
@media only screen and (min-width: 913px) and (max-width: 1280px) {
    .miniContainer {
        margin-top: 15%;
        width: 60%;
    }

    .modalBtn{
        font-size: 1.5rem;
        height: 8%;
    }
}

@media only screen and (min-width: 820px) and (max-width: 912px) {
    .miniContainer {
        margin-top: 30%;
        width: 80%;
    }
    .modalBtn{
        font-size: 2rem;
    }
}


@media only screen and (min-width: 540px) and (max-width: 819px) {
    .miniContainer {
        margin-top: 25%;
        width: 80%;
    }
}


@media only screen and (max-width: 539px) {
    .miniContainer {
        margin-top: 35%;
        width: 90%;
    height: 50%;

    }
    
    .modalBtn{
        font-size: 1rem;
        height: 8%;
        margin-top: 0.5rem;
        text-align: center;
    }
}


@media only screen and (max-width: 280px) {
    .miniContainer {
        margin-top: 30%;
        width: 90%;
    }

    .modalBtn{
        font-size: 1rem;
    }
}