:root {
  --main-color: white;
  --title-color: black;
  --background-color: rgb(248, 243, 243);
  --title-font-family: "Dancing Script", cursive;
  --indicator-is-active: gray;
}

.carousel {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 600px;
}

.contentContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.slide {
  border-radius: 0.5rem;
  box-shadow: 4px 4px 10px #666;
  text-align: center;
  padding: 0;
  height: 550px;
  width: 600px;
}

.arrow {
  position: absolute;
  width: 2.5rem;
  height: 2.5rem;
  color: #666;
  color: white;
  filter: drop-shadow(0px 0px 5px #555555);
}

.arrowLeft {
  left: 0.2rem;
  top: 15rem;
  z-index: 1;
}

.arrowRight {
  right: 0.2rem;
  top: 15rem;
  z-index: 1;
}

.indicators {
  position: absolute;
  width: 10rem;
  height: 7rem;
  top: 60%;
}

.indicatorsMiddleContainer {
  display: flex;
  position: absolute;
  top: 90%;
  left: 30%;
}

.indicator {
  background-color: #f09c7b;
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  border: none;
  outline: none;
  box-shadow: 0px 0px 5px #202020;
  margin: 0 0.2rem;
  cursor: pointer;
}

.slideHidden {
  display: none;
}

.indicatorInactive {
  background-color: var(--indicator-is-active);
}

.dishesTitle {
  background-color: var(--main-color);
}

.titleHidden {
  display: none;
}

.title {
  color: var(--title-color);
  font-size: 2rem;
  font-weight: bolder;
  font-family: var(--title-font-family);
  min-height: 100px;
  border-radius: 10px;
  width: 100%;
  text-align: center;
  margin-top: 1rem;
}

.price {
  font-size: 1.5rem;
  font-weight: bold;
  color: #4f4e4e;
}


/*Medie queries*/
@media only screen and (max-width: 768px) {
  .title {
    margin: 0.5rem 0 0 0;
    padding-bottom: auto;
  }
  .carousel {
    height: 500px;
    width: 500px;
  }

  .slide {
    border-radius: 0;
  }

  .arrowLeft {
    left: 0.1rem;
    top: 32%;
  }

  .arrowRight {
    right: 0.1rem;
    top: 32%;
  }

  .indicatorsMiddleContainer {
    top: 60%;
  }

  .indicators {
    top: 63%;
  }

  .slide {
    height: 500px;
    width: 500px;
  }
}

@media only screen and (width: 768px) {
  .carousel {
    margin: 1.5rem 0 1rem 0;
  }
}

@media only screen and (max-width: 540px) {
  .title {
    margin: 0.5rem 0 0 0;
    padding-bottom: auto;
  }
  .carousel {
    height: 400px;
    width: 400px;
    margin: 2rem 0 2rem 0;
  }
    .indicators {
    top: 55%;
  }

  .slide {
    height: 400px;
    width: 400px;
  }

}

@media only screen and (max-width: 430px) {
  .title {
    margin: 0.2rem 0 0 0;
    padding-bottom: auto;
  }

  .carousel {
    height: 300px;
    width: 300px;
    margin: 2rem 0 2rem 0;
  }
  .arrowLeft {
    top: 25%;
    left: -1.5rem;
  }

  .arrowRight {
    top: 25%;
    right: -1.5rem;;
  }
  .indicators {
    top: 45%;
  }
  .slide {
    height: 290px;
    width: 350px;
  }
}

@media only screen and (max-width: 290px) {
  .indicators {
    top: 40%;
  }
  .slide {
    height: 250px;
    width: 260px;
  }

  .arrowLeft {
    top: 25%;
    left: 0.6rem;
  }

  .arrowRight {
    top: 25%;
    right: 0.6rem;

  }
}