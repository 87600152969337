:root {
  --image-container-background-color: rgb(248, 243, 243);
  --text-font-family: Helvitica, "bradley Hand";
}

.container {
  margin: 12rem 0 7rem 0;
  font-family: text-font-family;
}

.mainFixedImage {
  position: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  margin: 0;
  padding: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
}

.title {
  font-weight: bold;
  font-size: 1.8rem;
  padding-bottom: 1rem;
}

.discriptionParagraph {
  width: 80%;
  font-size: 1.6rem;
}

/*Group A*/
.groupAContainer {
  background-color: rgb(247, 244, 239, 0.3);
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  white-space: pre-line;
  margin-bottom: 5rem;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(6px);
}

.groupAImageContainer, .groupBImageContainer {
  width: 50%;
  height: auto;
  padding: 1.7rem 0 1.7rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  align-self: center;
}

.groupASmallImageContainer, .groupBSmallImageContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  width: 95%;
}

.imageMiniContainer {
  flex-basis: 300px;
  padding: 1px;
  text-align: center;
  height: 250px;
}

.groupAImage, .groupBImage {
  object-fit: cover;
  object-position: center;
  width: 100%;
  vertical-align: middle;
  border-radius: 5px;
  height: 100%;
  border: 2px solid black;
  border-radius: 5px;
}

.groupAImage:hover, .groupBImage:hover {
  cursor: pointer;
}

.groupADescription {
  width: 45%;
  height: auto;
  padding: 0.5rem 0rem 0.5rem 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: pre-line;
}

/*Group B*/
.groupBContainer {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  margin-top: 20%;
  background-color: rgb(247, 244, 239, 0.4);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(6px);
}

.groupBImage:hover {
  cursor: pointer;
}

.groupBDescription {
  width: 45%;
  height: auto;
  padding: 0.5rem 0rem 0.5rem 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: pre-line;
}

/*Modal*/
.modalImageContainer {
  display: flex;
  flex-direction: column;
}

.modalImage {
  width: 90%;
  height: 80%;
  border: 3px solid black;
}


/*Media queris*/
@media only screen and (max-width: 1300px) {
  .groupAContainer,
  .groupBContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .groupAContainer {
    margin-bottom: 3rem;
  }

  .groupBContainer {
    flex-direction: column-reverse;
  }

  .groupADescription,
  .groupBDescription {
    width: 75%;
    height: auto;
    margin-bottom: 3%;
  }

  .groupBDescription {
    margin-top: 2rem;
  }

  .discriptionParagraph {
    width: 100%;
  }

  .groupAImageContainer,
  .groupBImageContainer {
    width: 75%;
  }
}

