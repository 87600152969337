.contactContainer {
    font-family: Helvitica, "bradley Hand";
    height: 100%;
}

.iframeContainer {
    width: 100%;
    overflow: hidden;
    height: 500px;
}

.iframe {
    width: 100vw;
    height: 100%;
    border: 0;
    margin: 0;
    padding-top: 5rem;
}

.smallContainer {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100vw;
    margin: 5rem 0 2rem 0;
    font-size: 1.3rem;
    text-align: center;
    height: 100%;
}

.adressContainer {
    min-height: 185px;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.adressMiniContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30%;
    width: 50%;
    margin-top: 1.2rem;
    font-weight: lighter;
}

.address {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
}

.phone {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
}

.phone:hover,
.address:hover {
    filter: invert(78%) sepia(61%) saturate(3923%) hue-rotate(324deg) brightness(99%) contrast(94%);
    filter: invert(44%) sepia(72%) saturate(466%) hue-rotate(333deg) brightness(89%) contrast(92%);
}

a {
    list-style: none;
    text-decoration: none;
    color: black;
    font-weight: bolder;
}

h2 {
    font-size: 1.5rem;
}

.openingHoursTitle {
    margin-bottom: 1rem;
}

.openingHours {
    min-height: 185px;
    display: flex;
    justify-self: center;
    flex-direction: column;
    align-items: center;
    width: 45%;
}

.daysAndHours {
    width: 50%;
    display: flex;
    margin-bottom: 0.5rem;
}

.days {
    width: 50%;
    text-align: right;
    padding-right: 1rem;
}

.hours {
    width: 50%;
}



/*Media queries*/
@media only screen and (min-width: 770px) and (max-width: 1350px) {
    .contactContainer {
        margin-bottom: 10rem;
    }

    .smallContainer {
        display: flex;
        flex-direction: column;
        margin-top: 4rem;
    }

    .adressContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 2rem;
    }

    .adressMiniContainer {
        flex-direction: column;
    }

    h2 {
        overflow-wrap: break-word;
        font-size: 2rem;
    }

    .openingHours {
        width: 100%;
    }

    .daysAndHours {
        flex-direction: column;
        justify-content: center;
        width: 90%;
    }

    .days {
        text-align: center;
        padding-right: 0;
        width: 100%;
    }

    .hours {
        width: 100%;
        text-align: center;
    }

    .phone {
        padding: 0% 2% 7% 2%;
        padding: 1rem 2rem 2rem 2rem
    }

    .address {
        padding: 1rem 2rem 2rem 2rem
    }


    .iframeContainer {
        height: 500px;
    }
}


@media only screen and (width: 1024px) {
    .iframeContainer {
        height: 300px;
    }

    .smallContainer {
        margin-top: 3rem;
    }
}


@media only screen and (max-width: 768px) {
    .contactContainer {
        margin-bottom: 10rem;
        margin-bottom: 5rem;

    }

    .iframeContainer {
        height: 400px;
    }

    .iframe {
        padding-top: 5rem;
    }

    .smallContainer {
        margin-top: 3rem;
        display: flex;
        flex-direction: column;
    }

    .adressContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 1rem;
    }

    .adressMiniContainer {
        flex-direction: column;
    }

    h2 {
        overflow-wrap: break-word;
    }

    .openingHours {
        width: 100%;
    }

    .daysAndHours {
        flex-direction: column;
        justify-content: center;
        width: 80%;
    }

    .days {
        text-align: center;
        padding-right: 0;
        width: 100%;
    }

    .hours {
        width: 100%;
        text-align: center;
    }

    .phone {
        padding: 0% 2% 7% 2%;
        padding: 1rem 2rem 2rem 2rem
    }

    .address {
        padding: 1rem 2rem 2rem 2rem
    }


    .openingHoursTitle {
        margin-bottom: 0rem;
    }
}


@media only screen and (min-width: 280px) and (max-width: 375px) {
    .iframeContainer {
        height: 300px;
    }
}