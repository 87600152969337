.container{
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.content{
    color: white;
    font-size: 2.5rem;
    font-family: "Helvitica en bradley Hand";
}